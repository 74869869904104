import React from "react";
import { FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const SocialShare = [
    {
        Social: <FaInstagram />,
        link: "https://www.instagram.com/petra.pfeifer.70/",
    },
];

const year = new Date().getFullYear();

const FooterTwo = () => {
    return (
        <div
            className="footer-style-2 ptb--30 bg_image bg_image--1"
            data-black-overlay="6"
        >
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner text-center">
                            <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare.map((val, i) => (
                                    <li key={i}>
                                        <a href={`${val.link}`}>{val.Social}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                            <p>
                                Copyright © {year} Petra Pfeifer. Alle Rechte vorbehalten. |{" "}
                                <Link to="/impressum">IMPRESSUM</Link> |{" "}
                                <Link to="/datenschutz">DATENSCHUTZ</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default FooterTwo;
