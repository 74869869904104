import { BrowserRouter, Routes, Route } from "react-router-dom";
import Master from "./home/Master";
import PageScrollTop from "./component/PageScrollTop";
import Impressum from "./home/Impressum";
import DSGVO from "./home/DSGVO";

function App() {
  return (
    <BrowserRouter basename={"/"}>
      <PageScrollTop>
        <Routes>
          <Route
            path={`${process.env.PUBLIC_URL}/impressum`}
            element={<Impressum />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/datenschutz`}
            element={<DSGVO />}
          />
          <Route element={<Master />} path="/" />
        </Routes>
      </PageScrollTop>
    </BrowserRouter>
  );
}

export default App;
