import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.pageTitle} || Medizinische Fußpflege Petra Pfeifer in Spiesen-Elversberg
                    </title>
                    <meta
                        name="description"
                        content="Ihre Adresse für gesunde Füße im Saarland. Medizinische Fußpflege vor Ort in Spiesen-Elversberg oder bei Ihnen Zuhause."
                    />
                </Helmet>
            </React.Fragment>
        );
    }
}

export default PageHelmet;
