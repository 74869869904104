import { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";

class DSGVO extends Component {
    render() {
        return (
            <Fragment>
                <Helmet pageTitle="Datenschutz" />
                <div className="about-wrapper pt--120">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12 mb--100">
                                <div className="section-title">
                                    <h2 className="title">Datenschutzerklärung nach DSGVO</h2>
                                </div>
                                <h3>1. Datenschutz</h3>
                                <p>
                                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                                    persönlichen Daten sehr ernst. Wir behandeln Ihre
                                    personenbezogenen Daten vertraulich und entsprechend der
                                    gesetzlichen Datenschutzvorschriften sowie dieser
                                    Datenschutzerklärung.
                                </p>
                                <h3>
                                    2. Erhebung und Speicherung personenbezogener Daten sowie Art
                                    und Zweck von deren Verwendung
                                </h3>
                                <p>
                                    <strong>a) Beim Besuch der Webseite</strong>
                                </p>
                                <p>
                                    Beim Aufrufen unserer Webseite werden durch den auf Ihrem
                                    Endgerät zum Einsatz kommenden Browser automatisch
                                    Informationen an den Server unserer Website gesendet. Diese
                                    Informationen werden temporär in einem sog. Logfile
                                    gespeichert. Folgende Informationen werden dabei ohne Ihr
                                    Zutun erfasst und bis zur automatisierten Löschung
                                    gespeichert:
                                </p>
                                <ul>
                                    <li class="firstItem">Name der abgerufenen Datei</li>
                                    <li>Datum und Uhrzeit des Abrufs</li>
                                    <li>übertragene Datenmenge</li>
                                    <li>Meldung, ob der Abruf erfolgreich war</li>
                                    <li>Beschreibung des Typs des verwendeten Webbrowsers</li>
                                    <li>verwendetes Betriebssystem</li>
                                    <li>die zuvor besuchte Seite</li>
                                    <li>Provider</li>
                                    <li class="lastItem">Ihre IP-Adresse</li>
                                </ul>
                                <p>
                                    Die genannten Daten werden durch uns zu folgenden Zwecken
                                    verarbeitet:
                                </p>
                                <ul>
                                    <li class="firstItem">
                                        Gewährleistung eines reibungslosen Verbindungsaufbaus der
                                        Website,
                                    </li>
                                    <li>
                                        Gewährleistung einer komfortablen Nutzung unserer Website,
                                    </li>
                                    <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                                    <li class="lastItem">zu weiteren administrativen Zwecken.</li>
                                </ul>
                                <p>
                                    Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs.1
                                    lit.f DSGVO. Unser berechtigtes Interesse folgt aus oben
                                    aufgelisteten Zwecken zur Datenerhebung. In keinem Fall
                                    verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse
                                    auf Ihre Person zu ziehen. Darüber hinaus setzen wir beim
                                    Besuch unserer Website Cookies sowie Analysedienste ein.
                                    Nähere Erläuterungen dazu erhalten Sie unter den Ziff. 4 und 5
                                    dieser Datenschutzerklärung.
                                </p>
                                <h3 data-fontsize="16" data-lineheight="24">
                                    3. Weitergabe von Daten
                                </h3>
                                <p>
                                    Eine Übermittlung Ihrer persönlichen Daten an Dritte zu
                                    anderen als den im Folgenden aufgeführten Zwecken findet nicht
                                    statt.
                                </p>
                                <p>
                                    Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
                                </p>
                                <ul>
                                    <li class="firstItem">
                                        Sie Ihre nach Art. 6 Abs.1 lit.a DSGVO ausdrückliche
                                        Einwilligung dazu erteilt haben,
                                    </li>
                                    <li>
                                        die Weitergabe nach Art.6 Abs.1 lit.f DSGVO erforderlich ist
                                        und kein Grund zur Annahme besteht, dass Sie ein
                                        überwiegendes schutzwürdiges Interesse an der
                                        Nichtweitergabe Ihrer Daten haben,
                                    </li>
                                    <li>
                                        für den Fall, dass für die Weitergabe nach Art. 6 Abs.1
                                        lit.c DSGVO eine gesetzliche Verpflichtung besteht, sowie
                                    </li>
                                    <li class="lastItem">
                                        dies gesetzlich zulässig und nach Art. 6 Abs.1 lit.b DSGVO
                                        für die Abwicklung von Vertragsverhältnissen mit Ihnen
                                        erforderlich ist.
                                    </li>
                                </ul>
                                <h3 data-fontsize="16" data-lineheight="24">
                                    4. Betroffenenrechte
                                </h3>
                                <p>Ihnen stehen die nachfolgenden Betroffenenrechte zu:</p>
                                <p>
                                    <strong>a) Auskunftsrecht</strong>
                                </p>
                                <p>
                                    Sie haben das Recht, von uns eine Bestätigung darüber zu
                                    verlangen, ob Sie betreffende personenbezogene Daten
                                    verarbeitet werden.
                                </p>
                                <p>
                                    <strong>
                                        b) Berichtigung/Löschung/Einschränkung der Verarbeitung
                                    </strong>
                                </p>
                                <p>
                                    Des Weiteren haben Sie das Recht, von uns zu verlangen, dass
                                </p>
                                <ul>
                                    <li class="firstItem">
                                        Sie betreffende unrichtige personenbezogene Daten
                                        unverzüglich berichtigt werden (Recht auf Berichtigung);
                                    </li>
                                    <li>
                                        Sie betreffende personenbezogene Daten unverzüglich gelöscht
                                        werden (Recht auf Löschung) und
                                    </li>
                                    <li class="lastItem">
                                        die Verarbeitung eingeschränkt wird (Recht auf Einschränken
                                        der Verarbeitung).
                                    </li>
                                </ul>
                                <p>
                                    <strong>c) Recht auf Datenübertragbarkeit</strong>
                                </p>
                                <p>
                                    Sie haben das Recht, Sie betreffende personenbezogene Daten,
                                    die Sie uns bereitgestellt haben, in einem strukturierten,
                                    gängigen und maschinenlesbaren Format zu erhalten und diese
                                    Daten einem anderen Verantwortlichen zu übermitteln.
                                </p>
                                <p>
                                    <strong>d) Widerrufsrecht</strong>
                                </p>
                                <p>
                                    Sie haben das Recht, Ihre Einwilligung jederzeit zu
                                    widerrufen. Durch den Widerruf der Einwilligung wird die
                                    Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
                                    erfolgten Verarbeitung nicht berührt.
                                </p>
                                <p>
                                    <strong>e) Widerspruchsrecht</strong>
                                </p>
                                <p>
                                    Ist die Verarbeitung Sie betreffender personenbezogener Daten
                                    für die Wahrnehmung einer Aufgabe, die im öffentlichen
                                    Interesse liegt (Art. 6 Abs. 1 Buchst. e) DSGVO) oder zur
                                    Wahrung unserer berechtigten Interessen (Art. 6 Abs. 1 Buchst.
                                    f) DSGVO) erforderlich, steht Ihnen ein Widerspruchsrecht zu.
                                </p>
                                <p>
                                    <strong>f) Beschwerderecht</strong>
                                </p>
                                <p>
                                    Sind Sie der Ansicht, dass die Verarbeitung der Sie
                                    betreffenden personenbezogenen Daten gegen die DSGVO verstößt,
                                    haben Sie unbeschadet anderweitiger Rechtsbehelfe das Recht
                                    auf Beschwerde bei einer Aufsichtsbehörde.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterTwo />
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
            </Fragment>
        );
    }
}

export default DSGVO;
