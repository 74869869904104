import ContactImage from "../../assets/images/site/pedicure-contact.webp";

const ContactOne = () => {
  return (
    <div className="contact-form--1">
      <div className="container">
        <div className="row row--35 align-items-start">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="section-title text-left mb--50">
              <h2 className="title">Termin vereinbaren.</h2>
              <p className="description">
                Whatsapp: <a href="tel:+4915253927417">+49 (0)152 53927417</a>
              </p>
              <p className="description">
                E-Mail:{" "}
                <a href="mailto:info@bestefüsse.de">info@bestefüsse.de</a>
              </p>
              <p className="description">
                Anfahrt:{" "}
                <a href="https://www.google.com/maps/dir//Schmalwiese+49+66583+Spiesen-Elversberg/@49.3147316,7.1458665,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4795c7d176f6da37:0x4e40b0ed6e29c25!2m2!1d7.1458665!2d49.3147316?entry=ttu">
                  Schmalwiese 49, 66583 Spiesen-Elversberg
                </a>
              </p>
            </div>
            <div className="form-wrapper">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    title="map"
                    className="gmap_iframe"
                    width="100%"
                    src="https://maps.google.com/maps?width=800&amp;height=400&amp;hl=en&amp;q=Schmalwiese 49, 66386 Spiesen&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="thumbnail mb_md--30 mb_sm--30">
              <img
                src={ContactImage}
                alt="pedicure"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactOne;
