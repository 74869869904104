import { Fragment, useEffect, useState } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import TextTransition, { presets } from "react-text-transition";
import FooterTwo from "../component/footer/FooterTwo";
import ContactOne from "../elements/contact/ContactOne";
import Helmet from "../component/common/Helmet";
import AboutImage from "../assets/images/site/about.webp";

const SlideList = [
  {
    textPosition: "text-left",
    category: "Hier sind sie richtig.",
  },
];

const Services = [
  {
    name: "Medizinische Fußpflege",
    description:
      "Nägel kürzen und glätten, Nagelfalz säubern, Verhornung entfernen, Füße eincremen mit kurzer Massage",
    cost: "38€",
  },
  {
    name: "Hühneraugen und Rhagaden",
    description: "je nach Aufwand",
    cost: "ab 4€",
  },
  {
    name: "Tamponade",
    cost: "4€",
  },
  {
    name: "Nagelpilzbehandlung",
    description: "je nach Aufwand",
    cost: "ab 5€",
  },
  {
    name: "Anfahrt bis 10km",
    cost: "4€",
  },
  {
    name: "Anfahrt ab 10km",
    cost: "6€",
  },
];

const BannerTexts = ["gesunde Füße", "schöne Füße", "beste Füße"];
const title = "Wir kümmern uns um Ihre Füße.";
const description =
  "Unsere Füße leisten Schwerstarbeit und werden am wenigsten beachtet. Tagein, tagaus tragen sie unser gesamtes Körpergewicht, müssen mal Trockenheit, mal Feuchtigkeit ertragen. Bekommen unsere Füße die notwendige Erholung und Regeneration? Ein Großteil unserer Bevölkerung läuft mit verhornten, wunden und zum Teil wirklich kranken Füßen herum.";

const Master = () => {
  const [phrase, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((phrase) => phrase + 1),
      3000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, [phrase, setIndex]);

  return (
    <Fragment>
      <Helmet pageTitle="Beste Füße" />
      {/* Start Slider Area   */}
      <div className="slider-wrapper">
        {/* Start Single Slide */}
        {SlideList.map((value, index) => (
          <div
            className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--main"
            key={index}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className={`inner ${value.textPosition}`}>
                    {value.category ? <span>{value.category}</span> : ""}
                    <h1 className="title">
                      Ihre Adresse für
                      <br />
                      <TextTransition springConfig={presets.default}>
                        <span>{BannerTexts[phrase % BannerTexts.length]}</span>
                      </TextTransition>
                    </h1>
                    <h2>im Saarland.</h2>
                    {value.description ? (
                      <figure>
                        <blockquote>
                          <p>{value.description}</p>
                        </blockquote>
                      </figure>
                    ) : (
                      ""
                    )}
                    {value.buttonText ? (
                      <div className="slide-btn">
                        <a
                          className="rn-button-style--2 btn-primary-color"
                          href={`${value.buttonLink}`}
                        >
                          {value.buttonText}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* End Single Slide */}
      </div>
      {/* End Slider Area   */}

      {/* Start About Area */}
      <div className="about-area about-position-top pb--120  bg_color--1">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img className="w-100" src={AboutImage} alt="Petra Pfeifer" />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-inner inner pt--100">
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <figure>
                      <blockquote>
                        <p>{description}</p>
                      </blockquote>
                      <figcaption>
                        {"—Petra Pfeifer, "}
                        <cite>{"med. Fußpflege, ärztlich geprüft"}</cite>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-wrapper pt--120">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-12">
                <div className="section-title">
                  <h2 className="title">{"Unser Service."}</h2>
                </div>
                <table>
                  <tbody>
                    {Services.map((service) => (
                      <tr>
                        <td>{service.name}</td>
                        <td>{service.description}</td>
                        <td>
                          <b>{service.cost}</b>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}

      {/* Start Portfolio Area */}
      <div className="portfolio-area pb--120 bg_color--1">
        <ContactOne />
      </div>
      {/* End Portfolio Area */}

      <FooterTwo />

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </Fragment>
  );
};

export default Master;
